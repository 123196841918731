<div class="overlay-container py-2 fx-column fx-gap-sm">
  <ng-template #loadingTemplate>
    <div class="loading-overlay disabled">
      <mat-spinner [diameter]="24" />
    </div>
  </ng-template>

  <ng-container *ngIf="contactInfo$ | async as contacts; else loadingTemplate">
    <div *ngIf="!isCollapsed" class="row justify-content-center">
      <span class="mat-caption text-bold">Have a question about this order?</span>
    </div>

    <div *ngIf="isDetailExpand" class="row justify-content-center">
      <div class="detail-section">
        <span class="font-weight-bold">Questions about your order?</span>
        <span class="detai-inline">Contact the Listing Concierge Team at {{genericCoordinator.phoneNumber}}<span *ngIf="genericCoordinator.email">or {{genericCoordinator.email}}</span></span>
      </div>

      <div *ngIf="!isDetailExpand" class="row m-0 justify-content-center">
        <span class="text-center">Contact: </span>
      </div>
    </div>

    <!-- Agent Display -->
    <ng-container *ngIf="showAgent && contacts.agent?._id" [ngTemplateOutlet]="contactInfo"
                  [ngTemplateOutletContext]="{info:contacts.agent, index: 0}" />

    <!-- CoLister Display -->
    <ng-container *ngIf="showCoLister && contacts.coListingAgent?._id">
      <mat-divider *ngIf="showAgent && contacts.agent?._id" class="position-relative" />
      <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{info:contacts.coListingAgent, index: 1}" />
    </ng-container>

    <!--Team Member(s) Display-->
    <ng-container *ngIf="showTeam && contacts.team">
      <mat-divider *ngIf="(showAgent && contacts.agent?._id) || (showCoLister && contacts.coListingAgent?._id)" class="position-relative" />
      <ng-container [ngTemplateOutlet]="teamInfo" [ngTemplateOutletContext]="{info:contacts.team,agentId:contacts.agent?.userId}" />
    </ng-container>

    <!-- Coordinator Display-->
    <ng-container *ngIf="showCoordinator">
      <ng-container *ngIf="contacts.coordinator else genericCoordinatorTemplate" [ngTemplateOutlet]="contactInfo"
                    [ngTemplateOutletContext]="{info:contacts.coordinator, isCoordinator: true}" />
    </ng-container>

    <ng-template #genericCoordinatorTemplate>
      <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{info:genericCoordinator}" />
    </ng-template>

  </ng-container>

  <!-- Template used to display contact info-->
  <ng-template #contactInfo let-info='info'>
    <div class="contact-info">
      <!-- Display contact details container-->
      <ng-container *ngIf="!isCollapsed && showCoordinator" [ngTemplateOutlet]="contactDetails"
                    [ngTemplateOutletContext]="{info:info}" />
      <ng-container *ngIf="!isCollapsed && !showCoordinator" [ngTemplateOutlet]="contactDetailsHorz"
                    [ngTemplateOutletContext]="{info:info}" />
      <ng-container *ngIf="isCollapsed && showCoordinator" [ngTemplateOutlet]="contactDetailsCollapsed"
                    [ngTemplateOutletContext]="{info:info}" />
    </div>
  </ng-template>

  <ng-template #contactDetails let-info="info"
               let-primaryAgent="primaryAgent"
  >
    <div class="fx-column">
      <!-- Display Profile photo -->
      <div *ngIf="info" class="row m-0 justify-content-center py-2">
        <lc-avatar [isVip]="info.isVipProfile" [photoUrl]="info?.profilePhotoUrl || info?.photoUrl" />
      </div>

      <div class="row m-0 justify-content-center">
        <span class="text-center full-name overflow-ellipsis mat-body-2" title="{{info?.firstName}} {{info?.lastName}}">{{info?.firstName}} {{info?.lastName}}</span>
      </div>

      <div *ngIf="info._id && info.phoneNumber" class="row m-0 justify-content-center">
        <span class="text-center phone overflow-ellipsis mat-caption" title="{{ info?.phoneNumber | phone }}">{{ info?.phoneNumber | phone }} </span>
      </div>

      <div *ngIf="info._id && info.email" class="row m-0 justify-content-center">
        <span class="text-center email overflow-ellipsis mat-caption" title="{{info?.email}}">{{ info?.email }} </span>
      </div>

      <div *ngIf="info._id && info.officeName && info.userId===primaryAgent" class="row m-0 justify-content-center">
        <span class="text-center email overflow-ellipsis mat-caption" title="{{info?.officeName}}">{{ info?.officeName }} </span>
      </div>

      <div *ngIf="showCoordinator && info" class="row m-0 justify-content-center">
        <span class="text-center generic-phone overflow-ellipsis mat-caption">{{genericCoordinator.phoneNumber}}</span>
      </div>

      <div *ngIf="!info._id || (showCoordinator && !info.email)" class="row m-0 justify-content-center">
        <span class="text-center generic-email overflow-ellipsis mat-caption">{{genericCoordinator.email}}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #contactDetailsCollapsed let-info="info">
    <!-- Display Profile photo -->
    <div *ngIf="info" class="row m-0 justify-content-center py-2">
      <lc-avatar [isVip]="info.isVipProfile" [photoUrl]="info?.profilePhotoUrl || info?.photoUrl" />
    </div>

  </ng-template>

  <ng-template #contactDetailsHorz let-info="info" let-primaryagent="primaryAgent">
    <div class="member-wrapper">
      <!-- Display Profile photo -->
      <div class="member-avatar justify-content-center">
        <lc-avatar [isVip]="info.isVipProfile" [photoUrl]="info?.profilePhotoUrl || info?.photoUrl" />
      </div>
      <div class="member-data">
        <div class="full-name overflow-ellipsis mat-body-2" title="{{info?.firstName}} {{info?.lastName}}">{{info.firstName}} {{info.lastName}}</div>
        <div class="phone text-faded overflow-ellipsis mat-caption" title="{{ info?.phoneNumber | phone }}">{{info.phoneNumber | phone }}</div>
        <div class="metro text-faded overflow-ellipsis mat-caption" title="{{info?.metroName}}">{{info.metroName}}</div>
        <div class="member-email email text-faded overflow-ellipsis mat-caption" title="{{info?.email}}">{{info.email}}</div>
        <div *ngIf="!primaryagent" class="office-name text-faded overflow-ellipsis mat-caption" title="{{info?.officeName}}">{{info?.officeName}}</div>
        <!-- Display primary agent office name during team members display -->
        <div *ngIf="(primaryagent === info?.userId)" class="office-name text-faded overflow-ellipsis mat-caption" title="{{info?.officeName}}">{{info.officeName}}</div>
      </div>
      <a class="xs member-edit" mat-icon-button [queryParams]="{ userId: info.userId }"
         [routerLink]="['/manage-order/' + marketingOrder?._id + '/profile/' + info?._id]">
        <mat-icon>edit</mat-icon>
      </a>
    </div>
  </ng-template>

  <!-- Template used to display team-->
  <ng-template #teamInfo let-agentId="agentId" let-info='info'>
    <div class="team-info fx-column fx-gap-sm">
      <!-- Display contact details container-->
      <ng-container *ngIf="!isCollapsed" [ngTemplateOutlet]="teamDetails" [ngTemplateOutletContext]="{info:info,agentId:agentId}" />
    </div>
  </ng-template>

  <!-- Used to show team details-->
  <ng-template #teamDetails let-agentId="agentId" let-info="info">
    <div class="mx-1 fx-row x-space-between">
      <!-- Team Contact Information-->
      <div class="team-contact fx-column">
        <span class="text-left font-weight-bold full-name overflow-ellipsis mat-body-2" title="{{info?.name}}">{{info?.name}}</span>
        <span class="text-left phone overflow-ellipsis mat-caption" title="{{ info?.phoneNumber | phone }}">{{ info?.phoneNumber| phone }} </span>
        <span *ngIf="info.email" class="text-left email overflow-ellipsis mat-caption" title="{{info?.email}}">{{ info?.email }} </span>
      </div>

      <!-- Edit Team button -->
      <a class="xs team-edit" mat-icon-button [queryParams]="{agentId:agentId}" [routerLink]="['/manage-order/' + marketingOrder?._id + '/team/'+ info?.id]">
        <mat-icon>edit</mat-icon>
      </a>
    </div>
    <div class="row my-2 m-0 pull-left">
      <span class="mat-body-2 text-bold">Members: </span>
    </div>
    <ng-container *ngFor="let aMember of info?.members; let isLast = last; let i = index">
      <div class="contact-info">
        <ng-container [ngTemplateOutlet]="contactDetailsHorz" [ngTemplateOutletContext]="{info:aMember,primaryAgent:agentId}" />
      </div>

      <mat-divider *ngIf="!isLast" class="position-relative" />
    </ng-container>
  </ng-template>
</div>
