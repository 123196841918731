<div
  #mainContainer
  id="mainContainer"
  [ngClass]="{containerClass: true, small: itemClass === 'manage-photo-grid', large: itemClass === 'product-grid'}">
  <!-- If images are processing display the overlay -->
  <div
    *ngIf="imagesProcessing"
    class="images-processing-overlay">
  </div>
  <div
    *ngFor="let item of items; let i = index"
    [class]="itemClass">
    <template
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{item: item, index: i}">
    </template>
  </div>
</div>
