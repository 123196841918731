<mat-drawer-container autosize class="order-management-container h-100">
  <mat-drawer #sidenav class="sidenav" [mode]="'side'" [opened]="!hideNavBar">
    <div class="h-100 clean-scroll-bar" id="nav-container">
      <!-- Navigation Menu -->
      <lc-navigation-menu [isExpanded]="isExpanded" [links]="tabs$ | async" />
    </div>
  </mat-drawer>

  <div class="fx-column h-100">
    <router-outlet />
  </div>
</mat-drawer-container>
