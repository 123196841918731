<div class="container text-center">
  <div class="wrapper">
    <h3 *ngIf="!isForcedLogout">
      You have logged out of the <span class="app-name">{{ appName }}</span>.
    </h3>
    <h3 *ngIf=" isForcedLogout">
      Your <span class="app-name">{{ appName }}</span> application session has expired. For security
      reasons you will need to Login again
    </h3>
    <p class="message">
      To protect your sensitive information we recommend that you close your browser.
    </p>
    <button *ngIf="isLoggedOut$ | async" color="primary" id="signin-button" mat-raised-button
            type="button" (click)="login()">Sign In</button>
  </div>
</div>