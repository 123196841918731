<div class="okta-container text-center">
  <div *ngIf="!hasError" class="wrapper">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <br><br>
      <h1 class="lead">Signing in ....</h1>
  </div>
  <div *ngIf="hasError" class="wrapper retry">
      <div class="alert" role="alert">
        {{errorMsg}}
      </div>
<div class="contact-message">
  {{contactMessage}}
</div>
<div *ngIf="isRetryButton" >
    <br>
      <button  color="primary" mat-raised-button type="button" (click)="retry()">Try again</button>
  </div>
    </div>
</div>
