<div cdkDropListGroup [ngClass]="{'dnd-container-vertical-scrollbar':verticalScrollbar, containerClass:true, 'row':true}">
  <div cdkDropList id="placeholder"  (cdkDropListDropped)="dropListDropped()"></div>
    <div *ngFor="let item of items; let i = index" cdkDropList [cdkDropListConnectedTo]="additionalDrops"
      [cdkDropListData]="[item]"
      [cdkDropListDisabled]="!canDrag || item.disabled" (cdkDropListDropped)="dropListDropped()">

    <div *ngIf="gridPlaceholder" id="grid-manage">
      <div cdkDrag [ngClass]="itemClass" (cdkDragDropped)="hidePlaceholder()" (cdkDragMoved)="dragMoved($event);">
        <div *cdkDragPlaceholder class="cdk-custom-grid-drag-placeholder"></div>
        <template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item, index: i}"></template>
      </div>
    </div>
    <div *ngIf="!gridPlaceholder" id="grid-product">
      <div cdkDrag [ngClass]="itemClass" (cdkDragDropped)="hidePlaceholder()" (cdkDragMoved)="dragMoved($event);">
        <div *cdkDragPlaceholder class="cdk-custom-product-drag-placeholder"></div>
        <template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item, index: i}"></template>
      </div>
    </div>
  </div>
</div>